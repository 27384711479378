import { show } from '@/services/modal';

import NAME from './name.json';

export default function editGroupDialog(group) {
  return new Promise(((resolve) => {
    show(
      () => import(/* webpackChunkName: "groups" */ './EzChangeGroup.vue'),
      group,
      {
        name: NAME,
        height: 'auto',
        classes: ['dialog', 'is-service-funnels'],
      },
      {
        'before-close': (e) => {
          resolve(e.params);
        },
      },
    );
  }));
}
