<script>
import { mapGetters } from 'vuex';
import groupsManager from '@/services/groupsManager';

export default {
  name: 'EzfSelectGroup',
  props: ['value'],

  data() {
    return {
      groups: [],
    };
  },

  computed: {
    ...mapGetters(['currentUserId']),
    group: {
      set(value) {
        this.$emit('input', value.id);
      },
      get() {
        return this.groups.find((g) => g.id === this.value);
      },
    },
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        this.loadGroups();
      },
    },
  },

  methods: {
    async loadGroups() {
      this.groups = await groupsManager.getList(this.currentUserId);
    },
  },
};
</script>

<template>
  <ez-form-item :label="$t('settings.group')">
    <ez-select
      :placeholder="$t('settings.groupPlaceholder')"
      :options="groups"
      v-model="group"
      item-label="name"
      :prop="{ openDirection: 'bottom' }"
    ></ez-select>
  </ez-form-item>
</template>
