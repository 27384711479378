<script>
import { mapState, mapActions } from 'vuex';
import { LOAD_DOMAINS } from '@/store/actions';

export default {
  name: 'EzfSelectDomain',
  props: ['value', 'user'],
  computed: {
    ...mapState(['domains']),
    domain: {
      set(value) {
        this.$emit('input', value.id);
      },
      get() {
        return this.domains.find((d) => d.id === this.value);
      },
    },
  },
  methods: {
    ...mapActions([LOAD_DOMAINS]),
  },
  created() {
    this[LOAD_DOMAINS](this.user);
  },
};
</script>

<template>
  <ez-form-item :label="$t('settings.domain')">
    <ez-select
      :placeholder="$t('settings.domainPlaceholder')"
      :options="domains"
      v-model="domain"
      item-label="name"
    ></ez-select>
  </ez-form-item>
</template>
